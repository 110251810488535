* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  /*font-size: 100%;*/
  /*vertical-align: baseline;*/
  /*background: transparent;*/
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cityBtn:hover{
  background-color: #DCDCDC;
}

.cityToolbarRow{
  margin-top: 25px;
}

.App {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  margin: 0 auto;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.restaurantRow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  color: black;
}
.cuisineTag{
  color: white;
  background-color: orangered;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  margin-right: 2px;
}

.yelpURL{
  text-decoration: none;
  color: orangered;
  font-size: 16px;
}

.yelpURL:hover{
  color: darkred;
}

.restaurantURL{
  text-decoration: none;
  color: #0081ff;
  font-size: 16px;
}

.restaurantURL:hover{
  color: darkgray;
}

.restaurantAddress{
  color: black;
}

.toggleDetailsButton{
  color: cornflowerblue;
  cursor: pointer;
}

.toggleDetailsButton:hover{
  color: darkblue;
}

.seeAllButton{
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
  background-color: white;
}

.rowURL{
  text-align: right;
}

.homepageIndex{
  padding: 50px 20px 100px 20px;
}

@media only screen and (max-width : 768px){
  /* Styles */
  .seeAllButton, .searchBar{
    margin-top: 30px;
  }

  .rowURL{
    text-align: left;
  }

  .homepageIndex{
    padding: 10px 20px 100px 20px;
  }
}